import React from "react"
import {
	TransitionGroup,
	Transition as ReactTransition,
} from "react-transition-group"

class Transition extends React.PureComponent {
	render() {
		const {children, location} = this.props;

		return (
			<TransitionGroup>
				<ReactTransition
					key={location.pathname}
					timeout={{
						enter: 500,
						exit: 500,
					}}
				>
					{status => (
						<div className={("page-animated page-animated--" + status)}>
							{children}
						</div>
					)}
				</ReactTransition>
			</TransitionGroup>
		)
	}
}

export default Transition