// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("/Users/mbokowy/Projekty/Intellect/intellect-site/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-bardziej-jsx": () => import("/Users/mbokowy/Projekty/Intellect/intellect-site/src/pages/bardziej.jsx" /* webpackChunkName: "component---src-pages-bardziej-jsx" */),
  "component---src-pages-career-full-stack-developer-jsx": () => import("/Users/mbokowy/Projekty/Intellect/intellect-site/src/pages/career/full-stack-developer.jsx" /* webpackChunkName: "component---src-pages-career-full-stack-developer-jsx" */),
  "component---src-pages-competences-shops-jsx": () => import("/Users/mbokowy/Projekty/Intellect/intellect-site/src/pages/competences/shops.jsx" /* webpackChunkName: "component---src-pages-competences-shops-jsx" */),
  "component---src-pages-competences-websites-jsx": () => import("/Users/mbokowy/Projekty/Intellect/intellect-site/src/pages/competences/websites.jsx" /* webpackChunkName: "component---src-pages-competences-websites-jsx" */),
  "component---src-pages-index-jsx": () => import("/Users/mbokowy/Projekty/Intellect/intellect-site/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-my-jsx": () => import("/Users/mbokowy/Projekty/Intellect/intellect-site/src/pages/my.jsx" /* webpackChunkName: "component---src-pages-my-jsx" */),
  "component---src-pages-projects-balcerzak-jsx": () => import("/Users/mbokowy/Projekty/Intellect/intellect-site/src/pages/projects/balcerzak.jsx" /* webpackChunkName: "component---src-pages-projects-balcerzak-jsx" */),
  "component---src-pages-projects-black-jsx": () => import("/Users/mbokowy/Projekty/Intellect/intellect-site/src/pages/projects/black.jsx" /* webpackChunkName: "component---src-pages-projects-black-jsx" */),
  "component---src-pages-projects-lemon-jsx": () => import("/Users/mbokowy/Projekty/Intellect/intellect-site/src/pages/projects/lemon.jsx" /* webpackChunkName: "component---src-pages-projects-lemon-jsx" */),
  "component---src-pages-unia-europejska-jsx": () => import("/Users/mbokowy/Projekty/Intellect/intellect-site/src/pages/unia-europejska.jsx" /* webpackChunkName: "component---src-pages-unia-europejska-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/mbokowy/Projekty/Intellect/intellect-site/.cache/data.json")

